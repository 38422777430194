<template>
  <section cta-content-image v-editable="blok" :style="[sectionStyles]" :class="{ 'has-bg-mobile': bgMobileStyle }">
    <div class="section-bg-mobile" v-if="bgMobileStyle" :style="[bgMobileStyle]"></div>
    <div class="container container--bleed">
      <div class="row section-row">
        <div class="section-content">
          <h2 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h2>
          <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
          <div v-if="blok.buttons" class="section-buttons">
            <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
          </div>
        </div>
        <a class="section-image" v-if="blok.image?.filename" :class="{ 'article-has-mobile': blok.image_mobile?.filename }" :href="url ? getLinkFieldUrl(url) : '/'">
          <img loading="lazy" :src="optimizeImage(blok.image.filename)" :alt="`Section Image ${blok.image.alt}`" class="article-image-element" />
          <img loading="lazy" v-if="blok.image_mobile?.filename" :src="optimizeImage(blok.image_mobile.filename)" :alt="`Section Image Mobile ${blok.image_mobile.alt}`" class="article-image-element-mobile" />
        </a>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/cta-content-image.scss" lang="scss"></style>
<script setup>
  const props = defineProps({ blok: Object });

  const title = computed(() => renderRichText(props?.blok?.title || {}));
  const description = computed(() => renderRichText(props?.blok?.description || {}));

  const sectionStyles = computed(() => {
    return {
      "background-color": props.blok?.background_color?.color || "#121217",
      "background-image": `url(${props.blok.background?.filename})`,
    };
  });

  const bgMobileStyle = computed(() => {
    if (!props.blok.background_mobile?.filename) return false;
    return {
      "background-image": `url(${props.blok.background_mobile?.filename})`,
    };
  });

  const url = computed(() => {
    if (props?.blok?.image_url) return props?.blok?.image_url;
    return props?.blok?.buttons[0]?.url || false;
  });
</script>
