<template>
  <section reviews-carousel v-editable="blok" :data-style="blok.style || 'center'">
    <div class="container">
      <div class="row">
        <h2 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h2>
        <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
      </div>
      <div class="row">
        <ClientOnly>
          <swiper-container id="reviews-carousel-swiper" ref="containerRef" class="section-slider" :space-between="32" :autoplay="autoplay" :pagination="pagination" :speed="500" :loop="false" :rewind="true" :navigation="navigation" :breakpoints="breakpoints" :watchSlidesProgress="true" :watchSlidesVisibility="true" :slides-per-view="1" :slides-per-group="1" :dynamic-bullets="true" :dynamic-main-bullets="3" :a11y="a11y">
            <swiper-slide v-for="blok in blok.reviews" :key="blok._uid">
              <StoryblokComponent :blok="blok" />
            </swiper-slide>

            <div slot="container-end" class="slider-footer">
              <div class="slider-dots"></div>
              <div title="Slider Arrow" class="slider-arrows">
                <a href="#prev" title="Previous Slide" aria-label="Previous Slide" role="button" class="slider-arrow slider-prev">
                  <span class="sr-only">Previous Slide</span>
                </a>
                <a href="#next" title="Next Slide" aria-label="Next Slide" role="button" class="slider-arrow slider-next">
                  <span class="sr-only">Next Slide</span>
                </a>
              </div>
            </div>
          </swiper-container>
        </ClientOnly>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/reviews-carousel.scss" lang="scss"></style>
<script setup>
  const props = defineProps({ blok: Object });

  const title = computed(() => renderRichText(props?.blok?.title || {}));
  const description = computed(() => renderRichText(props?.blok?.description || {}));

  const containerRef = ref(null);
  const swiper = useSwiper(containerRef);

  const a11y = {
    id: "reviews-carousel-swiper",
  };

  const navigation = {
    nextEl: "[reviews-carousel] .slider-next",
    prevEl: "[reviews-carousel] .slider-prev",
  };

  const autoplay = {
    delay: 7000,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
  };

  const breakpoints = {
    768: {
      slidesPerGroup: 1,
      slidesPerView: 1,
      dynamicBullets: false,
    },
    992: {
      slidesPerGroup: 3,
      slidesPerView: 3,
    },
  };

  const pagination = {
    clickable: true,
    el: "[reviews-carousel] .slider-dots",
  };
</script>
