<template>
  <section feature-slider v-editable="blok" :data-style="blok.style || 'default'">
    <div class="container">
      <div class="row row-header">
        <h2 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h2>
        <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
        <div v-if="blok.buttons && blok.style === 'default'" class="section-buttons">
          <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
        </div>
      </div>
      <div class="row">
        <ClientOnly>
          <swiper-container id="feature-slider-swiper" ref="containerRef" :speed="500" :loop="false" :rewind="true" :keyboard="{ enabled: true }" :slides-per-view="1" :breakpoints="breakpoints" :space-between="32" :css-mode="true" :pagination="pagination" :navigation="navigation" :a11y="a11y">
            <swiper-slide v-for="blok in blok.slides" :key="blok._uid">
              <StoryblokComponent :blok="blok" />
            </swiper-slide>

            <div slot="container-end" class="slider-footer" v-if="blok?.slides?.length > 3">
              <div class="slider-dots"></div>
              <div title="Slider Arrow" class="slider-arrows">
                <a href="#prev" title="Previous Slide" aria-label="Previous Slide" role="button" class="slider-arrow slider-prev">
                  <span class="sr-only">Previous Slide</span>
                </a>
                <a href="#next" title="Next Slide" aria-label="Next Slide" role="button" class="slider-arrow slider-next">
                  <span class="sr-only">Next Slide</span>
                </a>
              </div>
            </div>
          </swiper-container>
        </ClientOnly>
      </div>
      <div class="row row-footer" v-if="blok.style === 'footer-cta'">
        <div class="section-footer" v-if="blok.footer" v-html="footer"></div>
        <div v-if="blok.buttons" class="section-buttons">
          <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/feature-slider.scss" lang="scss"></style>
<script setup>
  const props = defineProps({ blok: Object });

  const containerRef = ref(null);
  const swiper = useSwiper(containerRef);

  const title = computed(() => renderRichText(props?.blok?.title || {}));
  const description = computed(() => renderRichText(props?.blok?.description || {}));
  const footer = computed(() => renderRichText(props?.blok?.footer || {}));

  const navigation = {
    nextEl: "[feature-slider] .slider-next",
    prevEl: "[feature-slider] .slider-prev",
  };

  const pagination = {
    clickable: true,
    el: "[feature-slider] .slider-dots",
  };

  const a11y = {
    id: "feature-slider-swiper",
  }

  const breakpoints = {
    768: {
      // slidesPerGroup: 1,
      slidesPerView: 1,
    },
    992: {
      enabled: props.blok?.slides?.length > 3,
      // slidesPerGroup: 3,
      slidesPerView: 3,
    },
  };
</script>
