<template>
  <section feature-carousel v-editable="blok" :data-style="blok.style || 'default'" :id="sectionId">
    <div class="container">
      <div class="row row-header" v-if="hasHeaderContent">
        <h2 class="section-title" v-if="blok?.title && title != ''" v-html="title"></h2>
        <div class="section-description" v-if="blok?.description && description != ''" v-html="description"></div>
        <div v-if="blok.buttons && blok.style === 'default'" class="section-buttons">
          <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
        </div>
      </div>
    </div>
    <div class="container container--bleed">
      <div class="row" v-if="!blok.hide_tabs">
        <ul class="section-tabs" ref="navEl">
          <li v-for="(blok, b) in blok.slides" :key="blok._uid" :class="{ active: b === active }">
            <span v-text="blok.label"></span>
          </li>
        </ul>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <swiper-container id="feature-carousel-swiper" ref="containerRef" :init="false">
          <swiper-slide v-for="blok in blok.slides" :key="blok._uid">
            <StoryblokComponent :blok="blok" />
          </swiper-slide>
        </swiper-container>
      </div>
      <div class="row row-footer" v-if="blok.style === 'footer-cta'">
        <div class="section-footer" v-if="blok.footer" v-html="footer"></div>
        <div v-if="blok.buttons" class="section-buttons">
          <StoryblokComponent v-for="blok in blok.buttons" :key="blok._uid" :blok="blok" />
        </div>
      </div>
    </div>
  </section>
</template>
<style src="~/assets/scss/section/feature-carousel.scss" lang="scss"></style>
<script setup>
  import { Pagination, A11y, Autoplay } from "swiper/modules";
  import "swiper/element/css/a11y";
  import "swiper/element/css/autoplay";
  import "swiper/element/css/pagination";

  const props = defineProps({ blok: Object });

  const title = computed(() => renderRichText(props?.blok?.title || {}));
  const description = computed(() => renderRichText(props?.blok?.description || {}));
  const footer = computed(() => renderRichText(props?.blok?.footer || {}));

  const hasHeaderContent = computed(() => {
    return [title.value, description.value].join("").length > 0;
  });

  const active = ref(0);

  const onSwiper = (e) => {
    active.value = e.realIndex;
  };

  const a11y = {
    id: "feature-carousel-swiper",
  };

  const pagination = {
    clickable: true,

    el: "[feature-carousel] .section-tabs",
    clickableClass: "section-tab--clickable",
    modifierClass: "section-tab-",
    bulletClass: "section-tab-item",
    bulletActiveClass: "active",
    renderBullet: function (index, className) {
      return `<li class="${className}"><span>${props.blok.slides[index].label}</span></li>`;
    },
  };

  const autoplay = {
    delay: 7000,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
  };

  const containerRef = ref(null);
  const swiper = useSwiper(containerRef, {
    modules: [Autoplay, Pagination, A11y],
    slidesPerView: 1,
    centeredSlides: false,
    loop: false,
    rewind: true,
    autoHeight: true,
    autoplay,
    pagination,
    a11y,
    on: {
      activeIndexChange: onSwiper,
    },
  });

  const sectionId = computed(() => {
    if (props.blok?.section) return textSlugify(props.blok?.section);
    return props.blok?._uid;
  });

  onMounted(() => {
    swiper.instance;
  });
</script>
